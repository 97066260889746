body.form-login {
  background-color: #ffffff;
  header {
    padding-top: 38px;
    padding-bottom: 15px;
    height: 103px;
    border-bottom: 1px solid #ebebeb;
    img {
      height: 28px;
      width: auto;
    }
  }
  .form-container {
    background-color: #ffffff;
    box-shadow: none;
    h2 {
      color: rgb(197, 40, 28);
    }
  }
}