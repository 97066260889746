body.form-login {
  background-color: #fff;
}

body.form-login header {
  height: 103px;
  border-bottom: 1px solid #ebebeb;
  padding-top: 38px;
  padding-bottom: 15px;
}

body.form-login header img {
  height: 28px;
  width: auto;
}

body.form-login .form-container {
  box-shadow: none;
  background-color: #fff;
}

body.form-login .form-container h2 {
  color: #c5281c;
}

/*# sourceMappingURL=login.a7aa4e58.css.map */
